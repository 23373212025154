<template>
    <div v-if="blockLoading">
        <p>Loading</p>
    </div>
    <div v-else-if="signed">
      <div style="width: 100%; padding: 2vh 2vw; text-align: center; border: solid 1px gainsboro; border-radius: 6px; background-color: white;">
        <h1 style="text-align: center;">{{ $t('This Document is Signed') }}</h1>
        <p style="font-size: 1.2rem;">{{ $t('All signers have signed this document through e-signing') }}</p>
        <br/>
        <button class="action-btn-e1" @click="showHistory" v-if="eventHistory.length === 0">
          <b-spinner v-if="onGetHistory" variant="primary"  small/>
          <p v-else style="margin: 0px; color:gray; font-weight: 600;">{{ $t('Show History') }}</p>
        </button>
        <div v-else style="align-items: center; justify-content: center; display: flex; padding: 20px">
          <div style="width: 60vw; max-width: 600px; align-items: center; border: gainsboro solid 1px; padding: 20px; border-radius: 8px;">
              <h2>{{ $t('Audit Trail') }}</h2>
              <br/>
              <div v-for="(event, i) of eventHistory" :key="`event-hist-${i}`"
                style="border-bottom: solid 1px gainsboro; padding-top: 8px; display: flex;">
                 <HistoryComponent :eventObject="event"/>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="background-color: white; padding:20px; border-radius: 8px; align-items: center; justify-content: center; display: flex; flex-direction: column;">
      <button v-if="order===null && this.confirmSigning === null" class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 25%;" @click="requestSign" :disabled="onRequestSign">
          <b-spinner v-if="onRequestSign" variant="primary"  small/>
          <span v-else>{{ $t('Initiate Signing') }}</span>
      </button>
      <div class="sign-flow-status"  :class="{'no-active-sign': this.currentStep == -1}" style="width: 100%; background-color: whitesmoke; margin-top: 24px;">
        <div style="width: 100%; display: flex;  justify-content: center; align-items: center;">
            <div class="s-step">
                <p class="step-counter" :class="{'s-step-done': this.currentStep > 0}" style="margin: 0px; color:white;">{{ '1' }}</p>
                <p style="font-size: 1.25rem; margin-top: 4px; margin-bottom: 0px; text-align: center;">{{ $t('Step 1') }}</p>
                <p style="font-size: 1.05rem; text-align: center;">{{ $t('Initiate Signing')}}</p>
                <div class="step-line" :class="{'s-step-done': this.currentStep > 1}"> </div>
            </div>
            <div class="s-step">
                <p class="step-counter" :class="{'s-step-done': this.currentStep > 1}" style="margin: 0px; color:white;">{{ '2' }}</p>
                <p style="font-size: 1.25rem; margin-top: 4px; margin-bottom: 0px; text-align: center;">{{ $t('Step 2') }}</p>
                <p style="font-size: 1.05rem; text-align: center;">{{ $t('Client Signing')}}</p>
                <div class="step-line" :class="{'s-step-done': this.currentStep > 2}"> </div>
            </div>
            <div class="s-step">
                <p class="step-counter" :class="{'s-step-done': this.currentStep > 2}" style="margin: 0px; color:white;">{{ '3' }}</p>
                <p style="font-size: 1.25rem; margin-top: 4px; margin-bottom: 0px; text-align: center;">{{ $t('Step 3') }}</p>
                <p style="font-size: 1.05rem; text-align: center;">{{ $t('Customer Signing')}}</p>
                <div class="step-line" :class="{'s-step-done': this.currentStep > 3}"> </div>
            </div>
            <div class="s-step">
                <p class="step-counter" :class="{'s-step-done': this.currentStep > 3}" style="margin: 0px; margin-top: -8px; color:white;">{{ '4' }}</p>
                <p style="font-size: 1.25rem; margin-top: 12px; text-align: center;">{{ $t('Complete') }}</p>
            </div>
        </div>
      </div>
      <!-- FLOW 0 -->
      <div v-if="this.confirmSigning !== null && this.currentStep === 1"  style="width: 100%; padding: 2vh 2vw">
        <div>
          <h2>{{ $t('Initiate Signing') }}</h2>
          <p style="font-size: 1rem;">{{ $t('Send the attached document for electronic signing request to chosen recipients.') }}</p>
        </div>
          <div style="background-color: rgb(249,249,249); padding: 12px; margin-top: 12px; border-radius: 8px;">
            <div style="display: flex; justify-content: space-between;">
              <h4> {{ $t('Initiator')}} </h4>
            </div>
            <div>
              <p style="margin: 2px">{{ 'Name: ' + this.confirmSigning.senderName}}</p>
              <p style="margin: 2px">{{ 'Email: ' + this.confirmSigning.senderEmail}}</p>
            </div>
          </div>
        <br/>
        <div>
          <h3>{{ $t('Signee') }}</h3>
          <div style="background-color: rgb(249,249,249); padding: 12px; margin-top: 12px; border-radius: 8px;">
            <div style="display: flex; justify-content: space-between;">
              <h4> {{ $t('Client Signatory')}} </h4>
              <button v-if="!onEditClient" id="edit-client-button" class="edit-signat-btn" :disabled="onInitiateSigning" @click="editClientSignatory">
                <feather-icon icon="EditIcon" class="mr-50" size="18"/>
              </button>
              <div v-else>
                <button id="save-client-button" class="edit-signat-btn" style="margin-right: 12px;" :disabled="onInitiateSigning" @click="cancelEditClientSignatory">
                  <feather-icon icon="XIcon" size="19"  class="mr-50" />
                </button>
                <button id="save-client-button" class="edit-signat-btn" style="margin-right: 12px;" :disabled="onInitiateSigning" @click="saveEditClientSignatory">
                  <feather-icon icon="SaveIcon" size="19"/>
                </button>
              </div>
            </div>
            <div v-if="onEditClient">
              <p style="margin: 0px;">{{ $t('Name') }}</p>
              <prozess-input id="client-name-tb" v-model="form.clientRecipientName" style="padding-top: 0px; margin-bottom: 0px;"
                  :error="errorField.includes('client-name-tb') ? $t('Required') : ''"/>
              <p style="margin: 0px; margin-top: -10px;">{{ $t('Email') }}</p>
              <prozess-input id="client-email-tb" v-model="form.clientRecipientEmail"  style="padding-top: 0px;"
                  :error="errorField.includes('client-email-tb') ? $t('Required') : ''"/>
            </div>
            <div v-else>
              <p style="margin: 0px;">{{ $t('Name') }}</p>
              <prozess-input id="client-name-tb" v-model="this.confirmSigning.clientRecipientName" disabled="true"
                  style="padding-top: 0px; margin-bottom: 0px;"/>
              <p style="margin: 0px; margin-top: -10px;">{{ $t('Email') }}</p>
              <prozess-input id="client-email-tb"  v-model="this.confirmSigning.clientRecipientEmail" disabled="true"
                  style="padding-top: 0px;"/>
            </div>
          </div>
          <div style="background-color: rgb(249,249,249); padding: 12px; border-radius: 8px; margin-top: 12px;">
            <div style="display: flex; justify-content: space-between;">
              <h4> {{ $t('Customer Signatory')}} </h4>
              <button v-if="!onEditCustomer" id="edit-customer-button" class="edit-signat-btn" :disabled="onInitiateSigning" @click="editCustomerSignatory">
                <feather-icon icon="EditIcon" class="mr-50" size="18"/>
              </button>
              <div v-else>
                <button id="save-customer-button" class="edit-signat-btn" style="margin-right: 12px;" :disabled="onInitiateSigning" @click="cancelEditCustomerSignatory">
                  <feather-icon icon="XIcon" size="19"  class="mr-50" />
                </button>
                <button id="save-customer-button" class="edit-signat-btn" style="margin-right: 12px;" :disabled="onInitiateSigning" @click="saveEditCustomerSignatory">
                  <feather-icon icon="SaveIcon" size="19"/>
                </button>
              </div>
            </div>
            <div v-if="onEditCustomer">
              <p style="margin: 0px;">{{ $t('Name') }}</p>
              <prozess-input id="customer-name-tb" v-model="form.customerRecipientName" style="padding-top: 0px; margin-bottom: 0px;"
                  :error="errorField.includes('customer-name-tb') ? $t('Required') : ''"/>
              <p style="margin: 0px; margin-top: -10px;">{{ $t('Email') }}</p>
              <prozess-input id="customer-email-tb" v-model="form.customerRecipientEmail"  style="padding-top: 0px;"
                  :error="errorField.includes('customer-email-tb') ? $t('Required') : ''"/>
            </div>
            <div v-else>
              <p style="margin: 0px;">{{ $t('Name') }}</p>
              <prozess-input id="customer-name-tb" v-model="this.confirmSigning.customerRecipientName" disabled="true"
                  style="padding-top: 0px; margin-bottom: 0px;"/>
              <p style="margin: 0px; margin-top: -10px;">{{ $t('Email') }}</p>
              <prozess-input id="customer-email-tb"  v-model="this.confirmSigning.customerRecipientEmail" disabled="true"
                  style="padding-top: 0px;"/>
            </div>
          </div>
        </div>
        <br/>
        <p v-if="this.confirmOrderError !== null" style="color:red; margin-top:12px; text-align: center;">{{ this.confirmOrderError }}</p>
        <button v-if="this.confirmSigning !== null" class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="initiateSigning"
          :disabled="disableSendSigning()">
          <b-spinner v-if="onInitiateSigning" variant="primary"  small/>
          <span v-else>{{ $t('Send Signing Request') }}</span>
        </button>
      </div>
      <div v-if="this.currentStep === 2"  style="width: 100%; padding: 2vh 2vw">
        <h2>{{ $t('Client Signing') }}</h2>
        <p>{{ $t('The client has been sent an email with a link to e-sign the document. They should check their inbox (and spam folder just in case) and follow the instructions to complete the process.') }}</p>
        <p style="font-weight: 700;">
          <span>{{ $t('Client E-mail: ') }}</span>
          <span style="font-weight: 400;">{{ order.customerEmail }}</span>
        </p>
      </div>

      <div v-if="this.currentStep === 3"  style="width: 100%; padding: 2vh 2vw">
        <h2>{{ $t('Customer Signing') }}</h2>
        <p>{{ $t('The customer has been sent an email with a link to e-sign the document. They should check their inbox (and spam folder just in case) and follow the instructions to complete the process.') }}</p>
        <p style="font-weight: 700;">
          <span>{{ $t('Customer E-mail: ') }}</span>
          <span style="font-weight: 400;">{{ order.clientEmail }}</span>
        </p>
      </div>
      <div v-if="this.order && this.debug">
         <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="refresh" :disabled="onRefresh">
            <span >{{ $t('Refresh') }}</span>
          </button>
          <br/>
      <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="sendTaskEmail">
            <span >{{ $t('SEND EMAIL') }}</span>
          </button>
        <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="getOrder">
            <span >{{ $t('Get Order') }}</span>
          </button>
        <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="getClientAuthenticationArtifact">
            <span >{{ $t('Get CLIENT Authentication') }}</span>
          </button>
        <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="getCustomerAuthenticationArtifact">
            <span >{{ $t('Get CUSTOMER Authentication') }}</span>
          </button>
        <br/>

        <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="getClientTaskStatus">
            <span >{{ $t('Get CLIENT Status') }}</span>
          </button>
        <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="getCustomerTaskStatus">
            <span >{{ $t('Get CUSTOMER Status') }}</span>
          </button>
          <br/>

        <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="getOriginalDoc">
            <span >{{ $t('Get Original DOC') }}</span>
          </button>
          <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="completeClientTask">
            <span >{{ $t('Complete Client Task') }}</span>
          </button>
        <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="completeCustomerTask">
            <span >{{ $t('Complete Customer Task') }}</span>
          </button>
        <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="getFinalDoc">
            <span >{{ $t('Get FINAL DOC') }}</span>
          </button>
        <button class="btn btn-primary d-flex justify-content-around align-items-center btn-secondary" style="width: 100%; margin-top: 12px" @click="retrieveSigned">
            <span >{{ $t('Retrieve SIGNED') }}</span>
          </button>
      </div>
    </div>
  </template>
  <script>
  import { swalConfirmDeleteOption } from '@/helpers/app'
  import RootEvents from '@/constants/rootEvents'
  import SignicatService from '@/services/signicat'
  import SigningService from '@/services/signing'
  import HistoryComponent from './HistoryComponent.vue'
  import contractTemplateService from '@/services/contractTemplate'
  import moment from 'moment'

  export default {
    components: {
      HistoryComponent,
    },
    props: {
      document: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        currentStep: -1,
        blockLoading: true,
        debug: false,
        onRequestSign: false,
        onInitiateSigning: false,
        onRefresh: false,
        confirmSigning: null,
        errorField: [],
        form: { },
        signed: false,
        resourceId: null,
        order: null,
        onEditCustomer: false,
        onEditClient: false,
        eventHistory: [],
        onGetHistory: false,
      }
    },
    watch: {
    $route: {
      immediate: true,
        async handler() {
          this.eventHistory = []
          this.onGetHistory = false
          this.onEditCustomer = false
          this.onEditClient = false
          this.blockLoading = true
          this.order = null
          this.signed = false
          this.onRequestSign = false
          this.confirmSigning = null // uncomment after test
          const openOrderResponse = await SigningService.getOpenOrder(this.$route.params.id)
          if (openOrderResponse.status === 204) {
            console.log('No open signing request')
          } else {
            this.order = openOrderResponse.data
            console.log('OPEN ORDEx', this.order)
            if (this.order.status === 'COMPLETED') {
              this.signed = true
            }
          }
          this.currentStep = this.getCurrentStep()
          this.blockLoading = false
        },
      },
    },
    methods: {
      disableSendSigning() {
        return this.onInitiateSigning || this.onEditCustomer || this.onEditClient
        || (this.confirmSigning.clientRecipientName === null || this.confirmSigning.clientRecipientName === '')
        || (this.confirmSigning.clientRecipientEmail === null || this.confirmSigning.clientRecipientEmail === '')
        || (this.confirmSigning.customerRecipientName === null || this.confirmSigning.customerRecipientName === '')
        || (this.confirmSigning.customerRecipientEmail === null || this.confirmSigning.customerRecipientEmail === '')
      },
      async completeClientTask() {
        SigningService.completeTask(this.order.clientTaskId)
      },
      async sendTaskEmail() {
        SigningService.sendTaskEmail(this.order.clientTaskId)
      },
      async completeCustomerTask() {
        SigningService.completeTask(this.order.customerTaskId)
      },
      async refresh() {
        this.onRefresh = true
        const openOrderResponse = await SigningService.getOpenOrder(this.document.uuid)
          if (openOrderResponse.status === 204) {
            console.log('No open signing request')
          } else {
            this.order = openOrderResponse.data
          }
          this.currentStep = this.getCurrentStep()
          this.onRefresh = false
      },
      getCurrentStep() {
        if (this.confirmSigning != null) {
            return 1
        }
        if (this.order !== null) {
          switch (this.order.status) {
            case 'CREATED': return 2
            case 'CLIENT_SIGNED': return 3
            case 'COMPLETED': return 4
            default: return -1
          }
        }
        return -1
      },
      async showHistory() {
        this.onGetHistory = true
        const clientTaskRes = await SignicatService.getTaskEvents(
          this.order.orderId,
          this.order.clientTaskId)
        const customerTaskRes = await SignicatService.getTaskEvents(
          this.order.orderId,
          this.order.customerTaskId)

        let clientTaskEventHistory = []
        if (clientTaskRes.status === 200) {
          clientTaskEventHistory = clientTaskRes.data
          clientTaskEventHistory.forEach((ev, idx) => {
            clientTaskEventHistory[idx].group = 'client'
            clientTaskEventHistory[idx].eventTime = new Date(Date.parse(ev.eventTime))
          })
        }
        let customerTaskEventHistory = []
        if (customerTaskRes.status === 200) {
          customerTaskEventHistory = customerTaskRes.data
          customerTaskEventHistory.forEach((ev, idx) => {
            customerTaskEventHistory[idx].group = 'customer'
            customerTaskEventHistory[idx].eventTime = new Date(Date.parse(ev.eventTime))
          })
        }
        this.eventHistory = clientTaskEventHistory.concat(customerTaskEventHistory)
        this.eventHistory = this.eventHistory.sort((b, a) => new Date(b.eventTime) - new Date(a.eventTime))
        this.eventHistory = this.eventHistory.filter(a => a.eventType !== 'TASK_CREATED' && a.eventType !== 'TASK_STARTED')
        console.log('eventHistory', this.eventHistory)
        this.onGetHistory = false
          return null
      },
      async requestSign() {
        this.onRequestSign = true
        const signingRequest = {
          documentUuid: this.document.uuid,
          documentVersionId: this.document.versions[0].id,
        }
        const confirmSignResponse = await SigningService.confirmSigningOrder(signingRequest)
        if (confirmSignResponse.status === 200) {
          this.confirmSigning = confirmSignResponse.data
          this.currentStep = 1
        }
        this.onRequestSign = false
      },
      async initiateSigning() {
        this.onInitiateSigning = true

        // remove these
        // this.confirmSigning.clientRecipientEmail = 'clientRecipientEmail@email.com'
        // this.confirmSigning.customerRecipientEmail = 'customerRecipientEmail@email.com'

        try {
          const signResponse = await SigningService.createSigningOrder(this.confirmSigning)
          console.log('signResponse', signResponse)

          if (signResponse.status === 201) {
            this.order = signResponse.data
            this.confirmSigning = null
            this.currentStep = this.getCurrentStep()
          } else {
            this.confirmOrderError = 'Error'
          }
        } catch (erx) {
            console.log('erx', erx.response.data)
            this.confirmOrderError = erx.response.data.error
        }
        this.onInitiateSigning = false
      },
      editCustomerSignatory() {
        this.onEditCustomer = true
        this.form = {
          customerRecipientName: this.confirmSigning.customerRecipientName,
          customerRecipientEmail: this.confirmSigning.customerRecipientEmail,
        }
      },
      editClientSignatory() {
        this.onEditClient = true
        this.form = {
          clientRecipientName: this.confirmSigning.clientRecipientName,
          clientRecipientEmail: this.confirmSigning.clientRecipientEmail,
        }
      },
      cancelEditClientSignatory() {
        this.form = {}
        this.onEditClient = false
      },
      cancelEditCustomerSignatory() {
        this.form = {}
        this.onEditCustomer = false
      },
      saveEditClientSignatory() {
        this.confirmSigning.clientRecipientName = this.form.clientRecipientName
        this.confirmSigning.clientRecipientEmail = this.form.clientRecipientEmail
        this.form = {}
        this.onEditClient = false
      },
      saveEditCustomerSignatory() {
        this.confirmSigning.customerRecipientName = this.form.customerRecipientName
        this.confirmSigning.customerRecipientEmail = this.form.customerRecipientEmail
        this.form = {}
        this.onEditCustomer = false
      },
      async getSignedDocument() {
        const signedDocRes = await SignicatService.getSignedDocument(
          this.signingResponse.orderId,
          this.signingResponse.taskId,
          this.signingResponse.documentId)
        this.viewRawPdfFileInNewTab(signedDocRes.data)
      },
      async getTaskStatus() {
        const taskStatusRes = await SignicatService.getTaskStatus(
          this.signingResponse.orderId,
          this.signingResponse.taskId)
        console.log('taskStatusRes', taskStatusRes)
      },
      async getPackagingTaskStatus() {
        const packTaskStatusRes = await SignicatService.getPackagingTaskStatus(
          this.order.orderId,
          this.order.packagingTaskId)
        return packTaskStatusRes
      },
      async getOrder() {
        return SignicatService.getOrder(this.order.orderId)
      },
      async getClientAuthenticationArtifact() {
       const authArtifact = await SignicatService.getAuthenticationArtifact(this.order.orderId, this.order.clientTaskId)
       console.log('CLIENT authArtifact', authArtifact)
      },
      async getCustomerAuthenticationArtifact() {
       const authArtifact = await SignicatService.getAuthenticationArtifact(this.order.orderId, this.order.customerTaskId)
       console.log('CUSTOMER authArtifact', authArtifact)
      },

      async getClientTaskStatus() {
       const authArtifact = await SignicatService.getTaskStatus(this.order.orderId, this.order.clientTaskId)
      },
      async getCustomerTaskStatus() {
       const authArtifact = await SignicatService.getTaskStatus(this.order.orderId, this.order.customerTaskId)
      },
      async retrieveSigned() {
       await SigningService.retrieveSigned(this.order.orderId)
      },
      async getFinalDoc() {
       const doca = await SigningService.getDocumentArchive(this.order.orderId)
       contractTemplateService.viewRawPdfFileInNewTab(doca.data)
      },
      async getOriginalDoc() {
       const doca = await SigningService.getDocument(this.order.documentId)
        contractTemplateService.viewRawPdfFileInNewTab(doca.data)
      },
    },
  }
  </script>

  <style lang="scss">
  @import "@/assets/scss/variables/_variables.scss";
  @import "@/assets/scss/company-contact-list.scss";
  @import '@/assets/scss/master-variables.scss';
  .edit-signat-btn{
    background-color: transparent;
    width: 20px;
    border: none;
    margin-right: 10px;
  }
  .s-step{
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    padding:12px;
    width: 16%;
  }
  .s-step-done{
    background-color:rgb(32, 152, 89) !important;
  }
  .step-line{
    position: absolute;
    z-index: 1;
    height: 3px;
    width: 16%;
    background-color:rgb(237, 237, 237);
    margin-top: 19px;
    margin-left: 16%;
  }
   .step-counter{
    z-index: 2;
    height: 40px;
    width: 40px;
    border-radius: 80px;
    justify-content: center;
    display: flex;
    align-items: center;
   }
   .step-counter{
    background-color:rgb(222, 222, 222);
   }
   .no-active-sign{
    opacity: 0.4;
   }
   .action-btn-e1{
    border: none;
    margin: 0px;
    padding: 6px 16px;
    border-radius: 4px;
    transition: 0.2s;
    min-width: 120px;
    outline: none;
   }
   .action-btn-e1:hover{
    background-color: rgb(218, 218, 218);
   }
  .prozess-signature-requests {

    &__more-info {
      background: var(--colour--body-bg);
      display: flex;
      border-radius: 30px;
      font-weight: bold;
      color: $colour--white;
      align-items: center;
      padding-right: 1rem;
      user-select: none;
      cursor: pointer;

      &-question-mark {
        background: var(--colour--secondary);
        border-radius: 50%;
        height: 100%;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        font-size: 1.3rem;
      }
    }
    &__contact {
      background: var(--colour--table-row);
      border-radius: 10px;
      padding: 1rem 2rem;
      margin-bottom: 1rem;
    }
    &__item {
      border-bottom: 1px solid $color--gray-y;
    }
  }
  </style>
