<template>
  <div>
    <sidebar-form
      :visible="showForm"
      :resource-id="resourceId"
      @saved="getDetails()"
      @close="onCloseForm"
    />
    <signature-request
      :document="resource"
      :visible="showSignatureRequest"
      @close="showSignatureRequest = false"
      @saved="handleSignatureRequestSaved"
    />
    <prozess-resource-view
      :resource="resource"
      :loading="loading"
      :fields="fields"
      :custom-fields="customFields"
      :can-update="$can('Update', 'Document')"
      @edit="$can('Update', 'Document') ? edit(resource.uuid) : null"
    >
      <template slot="additional-buttons">
        <b-dropdown
          v-if="isSignantEnabled"
          :text="$t('Actions')"
          variant="outline-secondary"
        >
          <b-dropdown-item
            id="signature-request-button"
            @click="handleSignatureRequest"
          >
            {{ $t('Signature Request') }}
          </b-dropdown-item>
          <b-tooltip
            v-if="!this.$can('Create', 'Signature')"
            target="signature-request-button"
            placement="left"
          >
            {{ $t('You dont have permission') }}
          </b-tooltip>
        </b-dropdown>

      </template>
      <template slot="body">
        <b-tabs
          content-class="mt-1"
          :value="allowedTabs.indexOf(this.$route.params.tab)"
          @input="handleTabInput"
        >
          <b-tab>
            <template #title>
              <feather-icon icon="CheckSquareIcon" /> {{ $t('Documents') }}
            </template>
            <document-preview
              v-if="!loading"
              :document="resource"
              @update="getDetails()"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="GitMergeIcon" /> {{ $t('Connections') }}
            </template>
            <entity-connections
              :resource="resource"
              @update="getDetails()"
            />
          </b-tab>
          <b-tab
            :title="$t('Signing Request (Signicat)')"
          >
            <SignicatSignatureRequests
              v-if="!loading"
              :document="resource"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon" /> {{ $t('History') }}
            </template>
            <b-overlay
              :show="loading"
              spinner-variant="primary"
              rounded="sm"
              variant="transparent"
              style="height: 100%;"
              class="d-flex flex-column"
            >
              <div class="card">
                <div class="card-body mb-5">
                  <prozess-resource-history
                    :history="items"
                    :loading="loading"
                    :history-pagination="{
                      currentPage,
                      total,
                      perPage
                    }"
                    @handleCurrentPage="handleCurrentPage"
                  />
                </div>
              </div>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </template>
    </prozess-resource-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GenericTabs } from '@/constants/app'
import documentService from '@/services/document'
import listMixinFactory from '@/mixins/list'
import RootEvents from '@/constants/rootEvents'
import EntityConnections from '@/views/shared/Connections/ListOnView.vue'
import SidebarForm from './Form.vue'
import SignatureRequest from './SignatureRequest.vue'
import SignicatSignatureRequests from './Signicat/index.vue'
import Preview from './Preview.vue'

export default {
  components: {
    SidebarForm,
    EntityConnections,
    DocumentPreview: Preview,
    SignatureRequest,
    SignicatSignatureRequests,
  },
  mixins: [
    listMixinFactory({
      service: documentService,
      isHistory: true,
    }),
  ],
  data() {
    return {
      resource: {
        customFields: {},
      },
      loading: true,
      fields: {
        name: 'name',
        description: 'description',
      },
      customFields: {},
      showSignatureRequest: false,
      tabs: [
        GenericTabs.PREVIEW,
        GenericTabs.CONNECTIONS,
        GenericTabs.SIGNICAT_SIGNING_REQUESTS,
        GenericTabs.HISTORY,
      ],
    }
  },
  computed: {
    ...mapState('signant', ['isSignantEnabled']),
    allowedTabs() {
      if (!this.isSignantEnabled) {
        return this.tabs.filter(tab => tab !== 'signing-requests')
      }
      return this.tabs
    },
  },
  beforeDestroy() {
    if (this.$refs.signantBadge) {
      this.$refs.signantBadge.style.display = 'none'
    }
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    handleTabInput(index) {
      this.$router
        .push({
          name: 'document-view',
          params: {
            id: this.$route.params.id,
            tab: this.allowedTabs[index] || null,
          },
        })
        .catch(() => { })
    },
    handleSignatureRequestSaved() {
      this.$root.$emit(RootEvents.SIGNATURE_REQUEST_SAVED)
    },
    handleSignatureRequest(e) {
      if (this.$can('Create', 'Signature') === false) return

      if (this.resource.mimeType !== 'application/pdf') {
        return this.$swal({
          title: this.$t('Document should be a pdf to perform signature request'),
          icon: 'warning',
          confirmButtonText: this.$t('Close'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
        })
      }

      this.showSignatureRequest = true
    },
    getDetails() {
      return documentService.getOne(this.$route.params.id).then(res => {
        const sortedCustomFields = res.data.customFieldMetadata
          .sort((a, b) => b.favorite - a.favorite)

        this.customFields = sortedCustomFields.reduce((acc, field) => {
          acc[field.key] = field
          return acc
        }, {})

        const customFields = {
          ...sortedCustomFields
            .reduce((acc, customField) => {
              const result = res.data.customFieldData
                .find(data => customField.id === data.metadataId)
              if (result) {
                acc[customField.key] = result.value
              }
              return acc
            }, {}),
        }

        this.resource = {
          ...res.data,
          customFields,
        }

        this.$root.$emit(RootEvents.SET_PAGE_TITLE, `${this.resource.name}`)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';

.prozess-document-view {
  &__signant-badge {
    position: absolute;
    top: 175px;
    right: 31px;
    background: $color--dark-w;
    z-index: 1;
    padding: 0.8rem;
  }
}
</style>
