import API from '@axios'
import { stringify as toQuery } from 'query-string'

export default {
  confirmSigningOrder(signingRequest) {
    return API.put('/api/signing/confirm', signingRequest)
  },
  createSigningOrder(signingRequest) {
    return API.put('/api/signing/create', signingRequest)
  },
  getOpenOrder(documentId) {
    return API.get(`/api/signing/get-open/${documentId}`)
  },
    getOrderList(documentId) {
      return API.get(`/api/signing/get-orders/${documentId}`)
    },
    completeTask(taskId) {
      return API.get(`/api/signing/bypass/complete-task/${taskId}`)
    },
    sendTaskEmail(taskId) {
      return API.get(`/api/signing/bypass/send-task-email/${taskId}`)
    },
    getDocumentArchive(orderId) {
      return API.get(`/api/signicat-signing/document/get-result/${orderId}`)
    },
    retrieveSigned(orderId) {
      return API.get(`/api/signing/retrieve-signed/${orderId}`)
    },

}
