<template>
    <div style="text-align: left;">
        <p style="margin: 0px;">{{ eventObject.eventType }}</p>
        <p>{{ eventObject.eventTime.toLocaleString() }}</p>
        <!-- <p>{{ moment(eventObject.eventTime.time).format('DD.MM.YYYY') }}</p> -->
    </div>
</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      eventObject: {
        type: Object,
        required: true,
      },
    },
  }
  </script>

<style lang="scss">
  @import '@/assets/scss/master-variables.scss';
</style>
